import React, { useState, useEffect } from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faFileExport, faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import './ResultsPage.css';
import Modal from 'react-modal'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

const StyledTable = styled(Table)({
  minWidth: 650,
});

const ResultsPage = () => {
  const location = useLocation();
  const rows = location.state.rows;
  const [results, setResults] = useState([]);
  const fetchedData = location.state.fetchedData;
  const navigate = useNavigate();
  const [globalDuration, setGlobalDuration] = useState('');
  const [durations, setDurations] = useState([]);
  const [selectedHardware, setSelectedHardware] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const theme = useTheme();

  const handleInfoClick = (info, event) => {
    const rect = event.target.getBoundingClientRect();
    setModalPosition({ top: rect.bottom, left: rect.left });
    setSelectedHardware(info);
    setIsModalOpen(true);
  };
  
  
  useEffect(() => {
    // Calculate the results
    if (Array.isArray(rows)) {
      // Calculate the results
      const newResults = rows.reduce((acc, row, index) => {
      // Skip the last row if it's empty
      if (index === rows.length - 1 && !row.qty && !row.hardware) {
        return acc;
      }

      const hardware = row.hardware;
      const subOption = row.selectedOption;
      const license = fetchedData.find(link => link.hardware.includes(hardware))?.license || '';
      const description = fetchedData.find(link => link.hardware.includes(hardware))?.description || '';
      const existingResult = acc.find(result => result.license === license);

      if (existingResult) {
        existingResult.qty += +row.qty; // convert qty to a number before adding it
      } else {
        // Determine the license based on the subOption
        let modifiedLicense = license;
        if (subOption === 'Essential') {
          modifiedLicense += '-E';
        } else if (subOption === 'Advantage') {
          modifiedLicense += '-A';
        }

        acc.push({ description, license: modifiedLicense, qty: +row.qty, hardware }); // convert qty to a number
      }

      return acc;
    }, []);

    setResults(newResults);
    }
  }, [rows, fetchedData]);

  const combinedResults = results.reduce((acc, result) => {
    const existingResult = acc.find((r) => r.license === result.license);
    if (existingResult) {
      existingResult.qty += Number(result.qty); // convert qty to a number before adding it
      existingResult.duration = durations[acc.indexOf(existingResult)];
    } else {
      acc.push({ ...result, qty: Number(result.qty), duration: durations[acc.length] }); // create a new object with the same license and the combined qty
    }
    return acc;
  }, []);

  const handleExport = () => {
    const header = [
      "Part Number", // Change "License" to "Part Number"
      "Quantity", // Change "Qty" to "Quantity"
      "Duration (Mnths)",
      "List Price",
      "Discount %",
      "Initial Term(Months)",
      "Auto Renew Term(Months)",
      "Billing Model",
      "Requested Start Date",
      "Notes"
    ];

    const mappedResults = combinedResults.map(result => ({
      "Part Number": result.license, // Map "License" to "Part Number"
      "Quantity": result.qty, // Map "Qty" to "Quantity"
      "Duration (Mnths)": result.duration || '', // Map "Duration" to "Duration (Mnths)" and set it to an empty string if it's not populated
      ...result
    }));

    const data = mappedResults.map(result => [
      result["Part Number"],
      result["Quantity"],
      result["Duration (Mnths)"],
      result.listPrice,
      result.discount,
      result.initialTerm,
      result.autoRenewTerm,
      result.billingModel,
      result.requestedStartDate,
      result.notes
    ]);

    const ws = XLSX.utils.aoa_to_sheet([header, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Results");
    XLSX.writeFile(wb, "results.xlsx");
  };

  useEffect(() => {
    if (globalDuration) {
      setDurations(results.map(() => globalDuration));
    } else {
      setDurations(results.map(() => ''));
    }
  }, [globalDuration, results]);

  const handleDurationChange = (index, value) => {
    if (value >= 0) {
      const newDurations = [...durations];
      newDurations[index] = value;
      setDurations(newDurations);
    }
  };

  const handleGlobalDurationChange = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setGlobalDuration(value);
    }
  };

  const handleClearDurations = () => {
    setGlobalDuration('');
    setDurations(results.map(() => ''));
  };

  const goBack = () => {
    navigate('/');
  };

  const handleDelete = (index) => {
    const newResults = [...results];
    newResults.splice(index, 1);
    setResults(newResults);
  };

  return (
    <div>
      <Typography variant="h4" component="h1" gutterBottom style={{ textAlign: 'center' }}>
        <Tooltip title="Go Home">
          <FontAwesomeIcon icon={faHome} className="home-icon" onClick={goBack} color={theme.palette.primary.main}/>
        </Tooltip>
          Results Page
        <Tooltip title="Export to XLSX Bom" placement="right">
          <FontAwesomeIcon icon={faFileExport} className="export-icon" onClick={handleExport} color='green'/>
        </Tooltip>
      </Typography>
      <div>
        <TextField 
          label="Global Duration" 
          value={globalDuration} 
          onChange={handleGlobalDurationChange}
          InputLabelProps={{
            style: { fontSize: '0.8rem' },
          }}
          sx={{
            '& .MuiInputBase-input': {
              padding: '6.5px 10px',
              width: '150px'
            },
            marginRight: '10px'
          }}
        />
        <Button variant="contained" color="primary" onClick={handleClearDurations}>Clear Durations</Button>
      </div>
      <TableContainer>
        <StyledTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">QTY</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">License</TableCell>
              <TableCell align="center">Duration</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {combinedResults.map((result, index) => (
              <TableRow key={index}>
                <TableCell align="center">{result.qty}</TableCell>
                <TableCell align="center">{result.description}</TableCell>
                <TableCell align="center">
                  {result.license}
                  <FontAwesomeIcon 
                    icon={faInfoCircle} 
                    className="info-icon" 
                    onClick={(event) => handleInfoClick({ hardware: result.hardware}, event)}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField 
                    type="text" 
                    placeholder="Enter duration" 
                    value={durations[index] || ''} // Set the value to the individual duration
                    disabled={globalDuration !== ''} // Disable the input if the global duration is not an empty string
                    onChange={(e) => handleDurationChange(index, e.target.value)} // Update the individual duration when the input is changed
                    sx={{
                      '& .MuiInputBase-input': {
                        padding: '6.5px 10px',
                        width: '110px'
                      },
                    }}
                  />
                </TableCell>
                <TableCell align='center'>
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={() => handleDelete(index)}
                    sx={{
                    backgroundColor: 'red',
                    '&:hover': {
                      backgroundColor: 'darkred',
                    },
                  }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <Modal 
        isOpen={isModalOpen} 
        onRequestClose={() => setIsModalOpen(false)}
        style={{
          content: {
            top: `${modalPosition.top}px`,
            left: `${modalPosition.left}px`,
            right: 'auto',
            bottom: 'auto'
          }
        }}
        className="modal"
      >
        <Typography variant="h6" component="h2">Hardware - Sub Options</Typography>
        <p>{selectedHardware?.hardware}</p>
        <Button variant="contained" color="primary" onClick={() => setIsModalOpen(false)}>Close</Button>
      </Modal>
    </div>
  );
};

export default ResultsPage;