// Footer.js
import React from 'react';

function Footer() {
  return (
    <footer style={{ position: 'fixed', left: 0, bottom: 0, width: '100%', backgroundColor: '#f8f9fa', textAlign: 'center', padding: '10px' }}>
      <p style={{ fontSize: '6px' }}><a href="https://www.flaticon.com/free-icons/donkey" title="donkey icons">Donkey icons created by Freepik - Flaticon</a></p>
    </footer>
  );
}

export default Footer;