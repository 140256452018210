import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faHome } from '@fortawesome/free-solid-svg-icons';
import Autosuggest from 'react-autosuggest';
import { useNavigate } from 'react-router-dom';
import { Box, Container, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Radio, RadioGroup, FormControlLabel, ButtonGroup,Typography } from '@mui/material';
import './FormPage.css';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Info from '@mui/icons-material/Info';

const FormPage = () => {
  const [rows, setRows] = useState([{ qty: '', hardware: '', subOptions: [], selectedOption: '' }]);
  const [fetchedData, setFetchedData] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isFirstRowFilled = rows[0].qty !== '' && rows[0].hardware !== '' && rows[0].selectedOption !== '';
    const isValid = isFirstRowFilled && rows.slice(0, -1).every(row => 
      row.qty !== '' && 
      row.hardware !== '' && 
      row.selectedOption !== '' && 
      !row.error
    );
    setIsFormValid(isValid);
  }, [rows]);

  useEffect(() => {
    fetch('https://mongo.thankfulcoast-52cfdea0.eastus.azurecontainerapps.io/api/fetchData') //fetch('http://localhost:3001/api/fetchData') https://mongo.thankfulcoast-52cfdea0.eastus.azurecontainerapps.io/api/fetchData
  .then(response => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  })
  .then(data => {
    // console.log(data); // Log the response data to the console
    setFetchedData(data);
  })
  .catch(error => console.error('Error:', error));
  }, []);

const handleSubmit = (event) => {
    event.preventDefault();

    // Navigate to the new page
    navigate('/results', { state: { rows, fetchedData } });
};

const handleChange = (index, field, value, event) => {
  const newRows = [...rows];
  if (event && event.clipboardData) {
    const pastedText = event.clipboardData.getData('Text');

    // Trim the pasted text and split it by newline characters
    const lines = pastedText.trim().split('\n');

    // Map over the lines and create a new row for each line
    const newRows = lines.map(line => {
      // Split the line by whitespace characters
      const parts = line.split(/\s+/);

      // Determine if the quantity is at the start or end of the line
      const qty = isNaN(parseInt(parts[0])) ? parts.pop() : parts.shift();
      const hardware = parts.join(' ');

      // Find the corresponding hardware data
      const hardwareData = fetchedData.find(data => data.hardware.includes(hardware));

      // Get the subOptions from the hardware data
      const subOptions = hardwareData ? hardwareData.subOptions.split(';') : [];

      // Check if the hardware exists
      const error = !hardwareData;

      // Return a new row
      return { qty, hardware, subOptions, selectedOption: subOptions[0] || '', error };
    });

    // Add a new blank row at the end
    newRows.push({ qty: '', hardware: '', subOptions: [], selectedOption: '' });

    // Replace the existing rows with the new rows
    setRows(newRows);

    // Prevent the default paste action
    event.preventDefault();
  } else {
    // If the field is 'qty', ensure the value is not less than 0
    if (field === 'qty' && value < 1) {
      value = 1;
    }

    newRows[index][field] = value;
    setRows(newRows);

    // If a radio button was selected, set the selectedOption
    if (field === 'selectedOption') {
        newRows[index].selectedOption = value;
    }

    // If the hardware was changed, check for subOptions
    if (field === 'hardware') {
      const hardware = fetchedData.find(link => link.hardware.includes(value));
      if (hardware && hardware.subOptions) {
        const subOptions = hardware.subOptions.split(';');
        newRows[index].subOptions = subOptions;
        // If there's only one sub option, automatically select it
        if (subOptions.length === 1) {
          newRows[index].selectedOption = subOptions[0];
        }
      }
    }

    // If the last row was changed, add a new row
    if (index === newRows.length - 1) {
      newRows.push({ qty: '', hardware: '', subOptions: [] });
    }

    setRows(newRows);
  }
};

const handleDelete = (index) => {
  const newRows = [...rows];
  newRows.splice(index, 1);
  setRows(newRows);
};

const getSuggestions = (value) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  if (inputLength === 0) {
    return [];
  }

  // Flatten the hardware arrays into a single array
  const hardwareList = fetchedData.flatMap(link => link.hardware);

  // Filter the hardware list based on the input value
  return hardwareList.filter(hardware =>
    hardware.toLowerCase().slice(0, inputLength) === inputValue
  );
};

const getSuggestionValue = (suggestion) => suggestion;

const renderSuggestion = (suggestion) => (
  <div className="suggestion">
    {suggestion}
  </div>
  );
  
  const handleReset = () => {
    setRows([{ qty: '', hardware: '', subOptions: [], selectedOption: '' }]);
  };

  const goBack = () => {
    // Reset the rows state
    setRows([{ qty: '', hardware: '', subOptions: [], selectedOption: '' }]);
    // Navigate to the home page
    navigate('/');
  };

  const handleBlur = (index) => {
    const newRows = [...rows];
    const row = newRows[index];
  
    // Find the corresponding hardware data
    const hardwareData = fetchedData.find(data => data.hardware.includes(row.hardware));
  
    if (hardwareData) {
      // If the hardware value matches an item in the list, remove the error
      row.error = false;
    } else {
      // If the hardware value does not match an item in the list, flag the row
      row.error = true;
    }
  
    setRows(newRows);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom style={{ textAlign: 'center' }}>
        <Tooltip title="Go Home">
          <FontAwesomeIcon icon={faHome} className="home-icon" onClick={goBack} color={theme.palette.primary.main} /> 
        </Tooltip>
        Adam's Best App
      </Typography>
      <Container>
        <form onSubmit={handleSubmit}>
          <TableContainer
          sx={{
            marginLeft: 'auto',
          }}
          >
            <Table
              sx={{
                width: '50%',
                margin: 'auto',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell 
                    align="center"
                    sx={{
                      padding: '0px',
                    }}
                  >
                    QTY
                  </TableCell>
                  <TableCell align="center">Hardware</TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="center"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: 'none',
                        verticalAlign: 'top',
                      }}
                    >
                      <TextField 
                        type="number" 
                        value={row.qty} 
                        onChange={(e) => handleChange(index, 'qty', e.target.value)}
                        onPaste={(e) => handleChange(index, 'qty', e.target.value, e)}
                        InputProps={{ 
                           style: { 
                             padding: '-30px', 
                             fontSize: '15px',
                             lineHeight: '1', 
                             width: '100px',
                             height: '35px',
                           },
                        }}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        sx={{ mt: 0 }}
                      />
                    </TableCell>
                    <TableCell 
                      align='center'
                      sx={{
                        border: 'none',
                        mt: -1,
                        verticalAlign: 'top',
                      }}
                    >
                      <Tooltip title={row.error ? "Hardware does not exist" : ""} open={row.error}>
                      <Box border={row.error ? 5 : 0} borderColor="error.main" borderRadius={1}>
                          <Autosuggest
                              suggestions={getSuggestions(row.hardware)}
                              onSuggestionsFetchRequested={({ value }) => handleChange(index, 'hardware', value)}
                              onSuggestionsClearRequested={() => {}}
                              getSuggestionValue={getSuggestionValue}
                              renderSuggestion={renderSuggestion}
                              inputProps={{
                                  value: row.hardware,
                                  onChange: (event, { newValue }) => handleChange(index, 'hardware', newValue),
                                  onPaste: (event) => handleChange(index, 'hardware', event.target.value, event),
                                  onBlur: () => handleBlur(index)
                              }}
                              theme={{
                                  suggestionsContainer: 'suggestionsContainer',
                                  suggestionsList: 'suggestionsList',
                                  suggestion: 'suggestion',
                                  input: { height: '30px' },
                                  style: { borderColor: row.error ? 'red' : 'initial', borderWidth: row.error ? 2 : 1, borderStyle: 'solid' }
                              }}
                            />
                        </Box>
                        </Tooltip>
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{
                        border: 'none',
                      }}
                    >
                      <RadioGroup
                        key={row.selectedOption}
                        value={row.selectedOption}
                        onChange={(e) => handleChange(index, 'selectedOption', e.target.value)}
                        sx={{
                          alignItems: 'felx-start',
                          mt: -1,
                        }}
                      >
                        {row.subOptions.map((option, i) => (
                          <FormControlLabel 
                            key={i}
                            value={option}
                            control={<Radio />}
                            label={option}
                            sx={{ 
                              mb: -1,
                              mt: -1,
                            }}
                          />
                        ))}
                        </RadioGroup>
                    </TableCell>
                    {row.qty || row.hardware ? (
                      <TableCell 
                        align='center'
                        sx={{
                          border: 'none',
                        }}
                      >
                        <Button 
                          variant="contained" 
                          //color="secondary" 
                          type="button" 
                          onClick={() => handleDelete(index)}
                          sx={{
                            backgroundColor: 'red',
                            '&:hover': {
                              backgroundColor: 'darkred',
                            },
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <ButtonGroup
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mr: '20%',
            }}
            >
              <Box>
                <Button 
                  variant="contained" 
                  color="primary" 
                  type="submit"
                  disabled={!isFormValid}
                  sx={{
                    mr: 2,
                  }}
                >
                  Submit
                </Button>
                <Button 
                  variant="contained" 
                  color="secondary" 
                  type="button"
                  onClick={handleReset}
                  sx={{
                    backgroundColor: 'red',
                    '&:hover': {
                      backgroundColor: 'darkred',
                    },
                  }}
                >
                  Reset
                </Button>
              </Box>
          </ButtonGroup>
        </form>
      </Container>
    </Box>
  );
};

export default FormPage;